





















































































































































































































































































































































































































































































































































































































































































































































































































































































































@import "../assets/scss/_v-calendar.scss";
@import "../assets/scss/_plan-schedule.scss";
.app-calendar{
  &-wrapper{
    position: relative;
  }
  &-loader{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 22;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-circle{
      width: 38px;
    }
  }
}
