/*==========  VCalendar Style  ==========*/
.v-calendar_drop{
  position: relative;
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;
    display: none;
  }
  .v-calendar_own{
    position: absolute;
    z-index: 32;
    top: calc(100% + 10px);
    left: 0;
    min-width: 180px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.34s ease-out;
  }
  &.is-active{
    .v-calendar_drop-overlay{
      display: block;
    }
    .v-calendar_drop-btn{
      background-color: rgba(94, 75, 241, 0.12);
    }
    .v-calendar_own{
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
}

.v-calendar_own{
  &.vc-container,
  .vc-container{
    border: none;
    border-radius: 16px;
    box-shadow: -2px 0px 16px rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }
  .vc-title{
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-transform: capitalize;
    transition: all .34s ease-out;
  }
  .vc-day-content{
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px;
    color: #000000;
    font-size: 14px;
    transition: all .16s ease-out;
    font-weight: 400!important;
    &:focus{
      background-color: #5E4BF1 !important;
    }
    &.is-disabled{
      pointer-events: none!important;
    }
  }
  .vc-highlight{
    width: 32px;
    height: 32px;
    transition: all .34s ease-out;
    background-color: #5E4BF1!important;
    border-color: #5E4BF1!important;
    border-radius: 6px!important;
  }
  .vc-day{
    margin-top: 1px;
    margin-bottom: 1px;
    &.is-not-in-month *{
      opacity: 1;
      color: #AAAAAA;
    }
  }
  .vc-weekday{
    color: #AAAAAA;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .vc-arrows-container{
    padding-top: 14px;
  }
  .vc-arrow{
    color: #161616;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    padding-bottom: 2px;
    transition: all .34s ease-out;
  }
  .vc-header{
    padding-top: 14px;
    margin-bottom: 8px;
  }
  .vc-nav-popover-container{
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    color: #161616;
  }
  .vc-weeks{
    padding: 0 16px 16px 16px;
  }
  .vc-nav-item{
    font-weight: 400;
    text-transform: capitalize;
    padding: 2px 0 4px 0;
    &.is-current{
      color: #161616;
    }
    &.is-active{
      box-shadow: none;
      background-color: #5E4BF1!important;
      color: #fff!important;
    }
  }
  .vc-nav-arrow{
    transition: all .34s ease-out;
  }
  .vc-nav-header{
    margin-bottom: 4px;
  }
  .vc-nav-title{
    color: #161616;
    font-weight: 500;
    font-size: 16px;
    transition: all .34s ease-out;
  }
  .vc-day-layer + .vc-day-content{
    color: #fff!important;
  }
  .vc-nav-title:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-nav-arrow:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-nav-item:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: #161616;
    box-shadow: none;
  }
  .vc-arrow:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-day-content:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}


/*==========  Hover  ==========*/

@media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)



