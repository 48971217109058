





































































































































































































































































.info-list-c:last-child{
  span{
    display: none;
  }
}
.more-info-layout{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px;
}
.table.table-mod{
  tr:first-child{
    td{
      padding-top: 0;
    }
  }
  tr:last-child{
    td{
      padding-bottom: 0;
    }
  }
}
.info-user-wrapper{
  display: flex;
  .info{
    width: 100%;
    padding-right: 40px;
  }
  .image{
    width: 120px;
    height: 120px;
    flex: 0 0 120px;
    border-radius: 8px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
