/*==========  plan-schedule Style  ==========*/
.title-wrapper-bt{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title{
    margin-right: 15px;
  }
}
.date-in{
  &-wrapper {
    display: flex;
    align-items: center
  }
  &-date{
    width: 100%;
    max-width: 200px;
    margin-right: 15px;
  }
  &-time{
    width: 100%;
    max-width: 94px;
  }
  &-sp{
    padding: 0 15px;
  }
}
.drop-search{
  position: relative;
  &-content{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    position: absolute;
    width: 100%;
    top: calc(100% + 8px);
    left: 0;
    z-index: 4;
    display: none;
    .list{
      padding: 0 8px;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      li{
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        transition: all .34s ease-out;
        &.is-active{
          background-color: rgba(94, 75, 241, 0.12);
          color: #5E4BF1;
        }
        &.disabled{
          color: #cbd5e0;
          pointer-events: none;
        }
      }
    }
    &:after{
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      left: 0;
      top: -10px;
    }
  }
  &-input{
    position: relative;
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      transition: all .34s ease-out;
    }
  }
  &-input.typed:not(.tp-not) + .drop-search-content,
  &-input.focus + .drop-search-content{
    display: block;
  }
  &-input.typed:not(.tp-not),
  &-input.focus{
    .icon{
      transform: translateY(-50%) rotate(180deg)
    }
  }
  &.type-2{
    .drop-search-content{
      min-width: 180px;
      color: #161616;
      font-size: 14px;
    }
  }
}
.drop-search-content .list li:hover{
  background-color: rgba(0, 0, 0, 0.08);
}
.drop-search:hover{
  .drop-search-content{
    display: block;
  }
}
.user-list-item{
  display: flex;
  align-items: center;
  .image{
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .info{
    margin-right: 10px;
  }
  .name{
    display: block;
    margin-bottom: 2px;
  }
  .btn{
    margin-left: auto;
  }
  &.h-pd{
    padding: 8px;
  }
}
.fc-v-event{
  position: relative;
  &-wrapper{
    display: none;
    &.is-active{
      display: block;
      .fc-v-event-overlay{
        display: block;
      }
    }
  }
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    display: none;
  }
  &-popup{
    background-color: #fff;
    padding: 16px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    position: fixed;
    min-width: 400px;
    z-index: 22;
  }
  &-body{
    padding-top: 8px;
  }
  &-list{
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .icon{
      width: 24px;
      flex: 0 0 24px;
      line-height: 0;
      margin-right: 10px;
      img{
        width: 100%;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
    &-user{
      display: flex;
      align-items: center;
      .image-wr{
        display: inline-block;
        position: relative;
        flex: 0 0 22px;
        margin-right: 6px;
      }
      .image{
        display: block;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        img{
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .name{
        white-space: nowrap;
        font-size: 16px;
      }
      .st-online{
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid #fff;
        background-color: #02BB9F;
        position: absolute;
        bottom: -1px;
        right: -1px;
      }
    }
  }
}
.months-min-width{
  display: inline-block;
  min-width: 100px;
  text-align: right;
  padding-right: 8px;
}
.event-item{
  padding-left: 18px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:after{
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #5E4BF1;
    background-color: rgba(94, 75, 241, 0.12);
    position: absolute;
    left: 0;
    top: 4px;
  }
  .content{
    padding-right: 32px;
  }
  .image{
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.app-calendar-wrapper:not(.touch-screen)::-webkit-scrollbar,
.app-calendar-wrapper:not(.touch-screen) *::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.app-calendar-wrapper:not(.touch-screen)::-webkit-scrollbar-track,
.app-calendar-wrapper:not(.touch-screen) *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.app-calendar-wrapper:not(.touch-screen)::-webkit-scrollbar-thumb,
.app-calendar-wrapper:not(.touch-screen) *::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.04);
}
.app-calendar-wrapper{
  .fc-timegrid-body .fc-timegrid-slots{
    border-right: 1px solid #E6E6E6;
    overflow: hidden;
  }
  .fc-timeGridDay-view .fc-col-header,
  .fc-timeGridWeek-view .fc-col-header{
    border-right: 1px solid #E6E6E6!important;
  }
  .fc .fc-timegrid-col.fc-day-today{
    background-color: rgba(1,1,1,0);
  }
  .fc-daygrid-dot-event{
    padding: 4px;
  }
  .fc .fc-timegrid-slot {
    height: 2.2em;
    border-bottom: 0;
  }
  .fc-scrollgrid-section.fc-scrollgrid-section-header > td{
    border-right: none;
  }
  .fc .fc-timegrid-slot-label-cushion{
    padding: 0 8px;
  }
  td, th{
    border: 1px solid #E6E6E6;
  }
  .fc .fc-timegrid-slot-minor {
    border-top-color: rgba(1,1,1,0);
  }

  td.fc-timegrid-slot-label{
    border: none;
    color: #767676;
    font-size: 12px;
    text-transform: uppercase;
  }
  .fc-scrollgrid{
    border-left: none;
    border-bottom: none;
    border-top: 0;
  }
  .fc-v-event{
    border-radius: 8px;
    padding: 8px;
    background-color: #5AA0F1;
    border: none;
    bottom: 0px;
    top: 1px;
    right: -5px;
    left: -2px;
    .fc-event-main{
      span{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      .title{
        font-style: unset;
        color: #fff;
        font-size: 14px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.2em;
      }
      .time{
        opacity: 0.7;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }
  .fc .fc-timegrid-now-indicator-line{
    display: none;
  }
  .fc .fc-timegrid-now-indicator-container{
    overflow: initial;
  }
  .fc-direction-ltr .fc-timegrid-now-indicator-arrow{
    left: auto;
    right: -5px;
    margin: 0;
    width: 8px;
    height: 8px;
    border: navajowhite;
    background-color: #FA665B;
    border-radius: 50%;
    z-index: 4;
    &:after{
      content: '';
      height: 1px;
      width: 100vw;
      background-color: #FA665B;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .fc .fc-scrollgrid-section-liquid > td{
    border-right: none;
  }
  .day-week{
    color: #767676;
    font-size: 12px;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2px;
    font-weight: 400;
  }
  .day-month{
    color: #161616;
    width: 25px;
    height: 23px;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    line-height: 23px;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .fc-col-header-cell.fc-day-today{
    .day-month{
      background: #FA665B;
      color: #FFFFFF;
    }
  }
  .fc .fc-daygrid-day.fc-day-today{
    background: rgba(94, 75, 241, 0.08);
  }

}


/*==========  Hover  ==========*/

@media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)



