






























































































































































.app-timeline-wrapper.type-2{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px;
}
.app-timeline-wrapper.type-2{
  .fc .fc-timeline-now-indicator-arrow{
    display: none;
  }
  .fc .fc-timeline-now-indicator-line{
    border-width: 0 0 0 2px;
    transform: translateX(23px);
    border-color: #FA665B;
    &:after{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #FA665B;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-5px);
    }
  }
  .fc-timeline-event{
    border-radius: 6px;
    height: 28px;
    position: relative;
    bottom: -5px!important;
    padding: 4px 8px;
  }
  .fc-event-main{
    > span{
      display: block;
      width: 100%;
      line-height: 1.2em;
    }
  }
  .evetn-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title{
      color: #161616;
      font-size: 14px;
    }
    .user{
      display: flex;
      align-items: center;
      line-height: 1em;
    }
    .image{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 8px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      color: #161616;
      font-size: 12px;
      margin-bottom: 1px;
    }
    .prof{
      color: #767676;
      font-size: 12px;
    }
  }
  .day-week{
    color: #767676;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2px;
    font-weight: 400;
  }
  .day-month{
    width: 22px;
    height: 22px;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    line-height: 23px;
    font-size: 12px;
    font-weight: 400;
    color: #161616;
  }
  .fc-timeline-slot-label.fc-day-today{
    .day-month{
      background: #FA665B;
      color: #FFFFFF;
    }
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid-section-header,
  .fc-scrollgrid-section-body{
    > td:last-of-type{
      border-right: 0;
    }
  }
  .fc-scrollgrid-section-body{
    > td:first-of-type .fc-scroller.fc-scroller-liquid-absolute{
      overflow: hidden!important;
    }
  }
  .fc-resource-timeline-divider.fc-cell-shaded{
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
  .fc-datagrid-cell-frame{
    display: flex;
    align-items: center;
  }
  .fc-scroller-harness{
    .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner{
      width: 100%;
    }
  }
  .fc-timeline-header {
    .fc-timeline-slot{
      border-left: 0;
    }
  }
  .fc-datagrid-cell-main{
    font-size: 14px;
    color: #161616;
  }
  .fc-datagrid-cell-cushion{
    padding: 0;
  }
}

